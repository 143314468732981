<template>
  <div>
    <div class="dashboard-navbar">
      <div class="dashboard-title">
        <i class="el-icon-menu"></i>
        {{ data.name }}
      </div>

      <div class="dashboard-tools">
        <el-button size="small" icon="el-icon-data-analysis" @click="addChart">
          <span>添加图表</span>
        </el-button>

        <el-button size="small" icon="el-icon-folder-add" @click="patchPanel" :loading="patchLoading">
          <span>保存面板</span>
        </el-button>

        <chart-tool
          :visibleObj="{ timeRange: true, interval: true }"
          :interval.sync="chartInterval"
          :timeRange.sync="timeRange"
        />
      </div>
    </div>

    <div class="loading-pre-container" v-loading="loading">
      <div v-if="!loading">
        <grid-layout
          :layout.sync="layoutData"
          :col-num="12"
          :row-height="30"
          :is-draggable="true"
          :is-resizable="true"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[10, 10]"
          :use-css-transforms="false"
        >
          <grid-item
            v-for="item in layoutData"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            :dragAllowFrom="'.panel-title'"
            :dragIgnoreFrom="'.el-dropdown-link'"
          >
            <div class="panel-wrapper" v-if="item.isNew" :class="{ new: item.isNew }">
              <div class="panel-title">{{ item.title }}</div>
              <el-button type="primary" size="small" @click="toConfigureNewPanel" icon="el-icon-setting"
                >To configure</el-button
              >

              <el-button type="danger" size="small" icon="el-icon-delete" @click="deleteNewPanel">Delete</el-button>
            </div>

            <div class="panel-wrapper" v-else>
              <div class="panel-title">
                <div class="dropdown-container">
                  <el-dropdown trigger="click" size="small">
                    <span class="el-dropdown-link">
                      {{ item.title }}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-edit-outline" @click.native="editPanel(item.uuid)">
                        编辑
                      </el-dropdown-item>
                      <el-dropdown-item divided icon="el-icon-delete" @click.native="delelePanel(item.uuid)">
                        删除
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>

              <div class="panel-chart">
                <chart-panel
                  :timeRange="timeRange"
                  :ref="'chart-panel-' + item.i"
                  :metric-info="item"
                  :auto-refresh="chartInterval"
                />
              </div>
            </div>
          </grid-item>
        </grid-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { getDashboardDetail, deletePanel, patchPanel } from "api/dashboard";
import ChartTool from "@/components/ChartTool";
import chartTool from "@/mixins/chart-tool";
import VueGridLayout from "vue-grid-layout";
import ChartPanel from "@/pages/Detail/components/ChartPanel";
import { cloneDeep } from "lodash";

export default {
  components: {
    ChartTool,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ChartPanel
  },

  mixins: [chartTool],

  data() {
    return {
      loading: true,
      layoutData: [],
      updateLoading: false,
      newFlag: false,
      data: {}
    };
  },

  computed: {
    dashboard() {
      return this.$route.params.dashboard;
    }
  },

  methods: {
    init() {
      this.loading = true;

      getDashboardDetail(this.dashboard).then(response => {
        if (response.code === 0) {
          this.data = response.data;
          this.layoutData = response.data.panels || [];

          if (this.layoutData.length > 0) {
            this.layoutData.forEach(item => (item.i = item.uuid));
          } else {
            this.newFlag = true;
            this.layoutData.unshift({
              x: 0,
              y: 0,
              w: 6,
              h: 6,
              i: this.layoutData.length + 1,
              title: "new_panel_title",
              isNew: true
            });
          }

          this.loading = false;
        }
      });
    },

    editPanel(uuid) {
      this.$router.push({
        path: `/dashboard/${this.dashboard}/config`,
        query: {
          type: "edit",
          panel: uuid
        }
      });
    },

    delelePanel(uuid) {
      this.$confirm("确定要删除此面板吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          deletePanel(this.dashboard, uuid).then(response => {
            if (response.code === 0) this.init();
          });
        })
        .catch(() => {});
    },

    addChart() {
      if (!this.newFlag) {
        this.newFlag = true;
        this.layoutData.unshift({
          x: 0,
          y: 0,
          w: 6,
          h: 6,
          i: this.layoutData.length + 1,
          title: "new_panel_title",
          isNew: true
        });
      }
    },

    toConfigureNewPanel() {
      this.$router.push({
        path: `/dashboard/${this.dashboard}/config`,
        query: {
          type: "add"
        }
      });
    },

    deleteNewPanel() {
      this.layoutData.splice(0, 1);
      this.newFlag = false;
    },

    patchPanel() {
      let data = cloneDeep(this.data);
      data.panels = this.layoutData;

      this.patchLoading = true;
      patchPanel(this.dashboard, data).then(response => {
        if (response.code === 0) {
          this.$notify({
            title: "Success",
            message: "保存成功",
            type: "success"
          });
        }
        this.patchLoading = false;
      });
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.vue-grid-item.vue-grid-placeholder {
  background: $color-primary !important;
}

.dashboard-navbar {
  @include flex(space-between, flex-start);

  .dashboard-title {
    font-size: 18px;
  }

  .dashboard-tools {
    @include flex(flex-start);

    .chart-tools {
      margin-bottom: 0;
    }
  }
}

.chart-handle-buttons {
  position: absolute;
  left: 0;
  top: 0;
}

.panel-wrapper {
  &.new {
    border-color: $color-primary;
    border-width: 2px;
    text-align: center;

    .el-button {
      margin-top: 30px;
    }
  }

  position: relative;
  height: 100%;
  background-color: #fff;
  border: 1px solid #dce1e6;
  border-radius: 4px;

  .panel-title {
    height: 28px;
    line-height: 28px;
    text-align: center;
    @include title(14px);
    transition: 0.2s all ease;

    &:hover {
      background-color: #f1f5f9;
    }

    .dropdown-container {
      max-width: 80%;
      margin: 0 auto;
      overflow: hidden;
    }

    .el-dropdown {
      max-width: 100%;
    }

    .el-dropdown-link {
      cursor: pointer;
      @include text-overflow();
    }
  }

  .panel-chart {
    height: calc(100% - 28px);
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}
</style>
